import { connect } from 'react-redux'
import { selectReports } from 'reports/stoppagesPareto/selectors'
import ParetoReportGraph from 'components/ParetoReportGraph'

import StoppagesParetoReportGraphTooltip from './StoppagesParetoReportGraphTooltip'
import StoppagesParetoReportGraphLabel from './StoppagesParetoReportGraphLabel'

const dataFormatter = duration => `${Math.round(duration)} min.`

const mapStateToProps = (state) => ({
  data: selectReports(state),
  dataFormatter,
  dataKey: 'duration',
  GraphLabel: StoppagesParetoReportGraphLabel,
  GraphTooltip: StoppagesParetoReportGraphTooltip
})

export default connect(mapStateToProps)(ParetoReportGraph)
