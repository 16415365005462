import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import StoppagesParetoReportHeader from 'reports/stoppagesPareto/StoppagesParetoReportHeader'
import StoppagesParetoReportLocations from 'reports/stoppagesPareto/StoppagesParetoReportLocations'
import StoppagesParetoReportGraph from 'reports/stoppagesPareto/StoppagesParetoReportGraph'

const StoppagesParetoReport = ({ isLoaded, plant }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        <StoppagesParetoReportHeader />
        <StoppagesParetoReportLocations />
        <StoppagesParetoReportGraph />
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

StoppagesParetoReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  isLoaded: state.init.isFinished
})

export default connect(mapStateToProps)(StoppagesParetoReport)
