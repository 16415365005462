import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectStoppageType } from 'reports/stoppagesPareto/selectors'

function truncate (string, length) {
  if (string.length > length) {
    return string.substr(0, length) + '...'
  } else {
    return string
  }
}

const StoppagesParetoReportGraphLabel = ({ x, y, stroke, payload, stoppageType, ...rest }) => {
  if (!stoppageType) {
    return null
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={8} textAnchor='start' fill='#666' transform='rotate(66)'>
        {stoppageType.symbol} - {truncate(stoppageType.description, 10)}
      </text>
    </g>
  )
}

StoppagesParetoReportGraphLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  payload: PropTypes.shape({
    value: PropTypes.number.isRequired
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
}

const mapStateToProps = (state, { payload: { value: stoppageTypeId } }) => ({
  stoppageType: selectStoppageType(state, stoppageTypeId)
})

export default connect(mapStateToProps)(StoppagesParetoReportGraphLabel)
