import { createAction } from 'redux-actions'

const Actions = {
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  SET_TOKEN: 'SET_TOKEN',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  SHOW_LOCATION: 'SHOW_LOCATION',
  HIDE_LOCATION: 'HIDE_LOCATION',
  SHOW_ALL: 'SHOW_ALL',
  HIDE_ALL: 'HIDE_ALL',
  RECEIVE_STOPPAGE_TYPES: 'RECEIVE_STOPPAGE_TYPES',
  RECEIVE_REPORTS: 'RECEIVE_REPORTS'
}

export default Actions

export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const setToken = createAction(Actions.SET_TOKEN)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const showLocation = createAction(Actions.SHOW_LOCATION)
export const hideLocation = createAction(Actions.HIDE_LOCATION)
export const showAll = createAction(Actions.SHOW_ALL)
export const hideAll = createAction(Actions.HIDE_ALL)
export const receiveStoppageTypes = createAction(Actions.RECEIVE_STOPPAGE_TYPES)
export const receiveReports = createAction(Actions.RECEIVE_REPORTS)
