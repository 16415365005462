import { connect } from 'react-redux'
import { selectLocationIds } from 'reports/stoppagesPareto/selectors'
import StoppagesParetoReportLocationToggle from 'reports/stoppagesPareto/StoppagesParetoReportLocationToggle'
import ProductionReportLocations from 'components/ProductionReportLocations'
import StoppagesParetoReportLocationSelectAll from './StoppagesParetoReportLocationSelectAll'
import StoppagesParetoReportLocationSelectNone from './StoppagesParetoReportLocationSelectNone'

const mapStateToProps = (state) => ({
  ids: selectLocationIds(state),
  Toggle: StoppagesParetoReportLocationToggle,
  SelectAll: StoppagesParetoReportLocationSelectAll,
  SelectNone: StoppagesParetoReportLocationSelectNone
})

export default connect(mapStateToProps)(ProductionReportLocations)
