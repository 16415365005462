import React from 'react'
import ReactDOM from 'react-dom'
import momentInit from 'lib/momentInit'
import { Provider } from 'react-redux'
import { addLocaleData, IntlProvider } from 'react-intl'
import en from 'react-intl/locale-data/en'
import pl from 'react-intl/locale-data/pl'
import de from 'react-intl/locale-data/de'
import createSagaMiddleware from 'redux-saga'
import Bus from 'lib/Bus'
import onError from 'lib/saga/onError'
import keepAlive from 'lib/saga/keepAlive'
import createStore from 'reports/stoppagesPareto/store'
import StoppagesParetoReport from 'reports/stoppagesPareto/StoppagesParetoReport'
import { setToken } from 'reports/stoppagesPareto/actions'
import rootSaga from 'reports/stoppagesPareto/saga'

const { plant, timezone, locale, messages } = window

momentInit()
addLocaleData([...en, ...pl, ...de])

const initialState = { config: { locale, timezone }, plant: { name: plant } }
const sagaMiddleware = createSagaMiddleware({ onError })
let store = createStore(initialState, [sagaMiddleware])
let saga

Bus.on('page:load', () => {
  const node = document.getElementById('stoppages-report')

  if (node) {
    store.dispatch(setToken(node.dataset.token))
    saga = keepAlive(rootSaga, sagaMiddleware)

    ReactDOM.render(
      <IntlProvider locale={locale} messages={messages}>
        <Provider store={store}>
          <StoppagesParetoReport />
        </Provider>
      </IntlProvider>,
      node
    )
  }
})

Bus.on('page:exit', () => {
  if (saga) {
    saga.cancel()
  }
})
